/*import jQuery from './vendor/jquery.min.js';*/

window.$ = window.jQuery = jQuery; 

// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function () {};
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

// Place any jQuery/helper plugins in here.

var AssignCurrentMenu = function(){
  var assigned=false;
  // var path = window.location.hash
  var path = window.location.pathname
  if(path!="" && path!="/"){
    //path = path.replace("/","");
    //path = path.replace("#","");
    var navID = "#nav"
    console.log(path);
    $("#nav a").each((idx,l) => {
      console.log($(l).attr("href"))
      if(path.startsWith($(l).attr("href")) && assigned==false){
        $(l).parent("li").addClass("current");
        assigned=true;        
      }else{
        $(l).parent("li").removeClass("current");
      }
    });
  }
  if(assigned==false){
    $("#nav a[href='index.html']").parent("li").addClass("current");
  }
  // if(path!=""){
  //   // Scroll to a certain element
  //   console.log("a[name='"+path+"']");
  //   document.querySelector("a[name='"+path+"']").scrollIntoView({ 
  //     behavior: 'smooth' 
  //   });
  // }
};

window.addEventListener('hashchange', function() {
  AssignCurrentMenu();
}, false);


var ProcessOnScrollShowScrollTop = function(){
  window.eleScrollTop = document.getElementById("scroll-top");  
  console.log(eleScrollTop);
  var myScrollFunc = function() {
      var y = window.scrollY;
      if (y >= 800) {
        eleScrollTop.className = "scrolltop-wrap show" 
      } else {
        eleScrollTop.className = "scrolltop-wrap hide"
      }
  };
  window.addEventListener("scroll", myScrollFunc);
};

jQuery(document).ready(function(){
  AssignCurrentMenu();
  //ProcessOnScrollShowScrollTop();
});
